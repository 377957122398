import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
      margin: "0.3rem",
      display: "flex",
      flexDirection: "column",
      maxWidth: 260,
      background: "linear-gradient(45deg, #eee, #ddd)",
      border: 0,
      borderRadius: theme.shape.borderRadius,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    content: {
      flex: 1,
    },
    actions: {},
  })
)

export default function ProductCard({ product }) {
  const hasVariants = product =>
    product.frontmatter.variant1Options &&
    product.frontmatter.variant1Options.length > 0
  const variant1AllOptions = hasVariants(product)
    ? product.frontmatter.variant1Options.join("|")
    : null
  const variant1Name = product.frontmatter.variant1Name || "Options"

  const classes = useStyles()
  const [currentVariant, setCurrentVariant] = React.useState(
    hasVariants(product) ? product.frontmatter.variant1Options[0] : null
  )
  const [isAlertOpen, setIsAlertOpen] = React.useState(false)

  const handleAddToCart = () => {
    setIsAlertOpen(true)
  }

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }

    setIsAlertOpen(false)
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })

  const handleVariantChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCurrentVariant(event.target.value as string)
  }
  return (
    <>
      <Card className={classes.root}>
        {product.frontmatter.thumbnail && (
          <CardMedia>
            <Img
              fluid={product.frontmatter.thumbnail.childImageSharp.fluid}
              alt={product.frontmatter.title + " - Featured Shot"}
            />
          </CardMedia>
        )}
        <CardContent className={classes.content}>
          <Typography>{product.frontmatter.title}</Typography>
          {hasVariants(product) && (
            <FormControl>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={currentVariant}
                onChange={handleVariantChange}
              >
                {product.frontmatter.variant1Options.map(variant => (
                  <MenuItem
                    key="variant"
                    value={variant.replace(/\[.*\]/g, "")}
                  >
                    {variant}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Typography>{formatter.format(product.frontmatter.price)}</Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {product.frontmatter.shortDescription}
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant={"contained"}
            color={"primary"}
            fullWidth
            className="snipcart-add-item"
            onClick={handleAddToCart}
            data-item-id={product.frontmatter.sku}
            data-item-price={product.frontmatter.price}
            data-item-url={product.fields.slug}
            data-item-description={product.frontmatter.shortDescription}
            data-item-image={
              product.frontmatter.thumbnail.childImageSharp.resize.src
            }
            data-item-name={product.frontmatter.title}
            data-item-custom1-name={variant1Name}
            data-item-custom1-options={variant1AllOptions}
            data-item-custom1-value={currentVariant}
          >
            add to cart
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          {product.frontmatter.title} was added to your cart
        </Alert>
      </Snackbar>
    </>
  )
}
